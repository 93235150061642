.swiper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1102;
}

.slider-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px 10px;
}

.swiper-slide {
  padding-top: 50px;
  text-align: center;
  font-size: 18px;
  background: #000000f0;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper__header-menu {
  color: white;
  display: flex;
  gap: 10px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1103;
} 

.swiper__file {
  display: flex;
  flex-direction: column;
}

.swiper-zoom-container {
  max-width: 500px;
  max-height: 500px;
}

.swiper__file-icon-container {
  display: flex;
  justify-content: center;
}

.swiper__file_text {
  margin-top: 8px;
  color: #ccc;
  line-height: 16px;
  font-size: 16px;
}

.swiper__file_link {
  margin-top: 8px;
  color: #ccc;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
}

.swiper__counter {
  position: fixed;
  top: 0;
  left: 10px;
  padding-top: 10px;
  padding-left: 10px;
}

.swiper__counter-number {
  color: #ccc;
  font-size: 13px;
}

.swiper__button {
  border: 0;
  outline: 0;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
}



.swiper__button--black:hover {
  background-color: #333;
}

.swiper__anchor {
  color: #fff;
  font-size: 20px;
  padding: 1px 6px;
}

.swiper__anchor:active,
.swiper__anchor:focus,
.swiper__anchor:visited {
  color: white;
}


.swiper__pdf {
  width: 800px;
  max-width: calc(90% - 2em);
  overflow: scroll;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.react-pdf__Document {
  overflow: hidden; /* Hide extra vertical scrollbar */
}

.react-pdf__Page {
  background-color: #000;
  display: flex;
  justify-content: center;
}

.swiper__pdf .react-pdf__Page {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: #000;
}

.swiper__pdf .react-pdf__message {
  padding: 20px;
  color: white;
}

.navBar {
    height: 60px;
    width: 100%;
    background-color: var(--zero-blue);
}

.container {
    background-color: var(--zero-blue);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 1200;
}

.finished {
    animation: slideUp 500ms ease 250ms 1 normal forwards;
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
        display: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.logoWrapper {
    position: relative;
    top: calc(50% - 60px - var(--sab) + var(--sat));
    transform: translateY(-50%);
}

.logo {
    width: 100%;
    max-width: 200px;
    animation: fadeIn 2s ease 0s 1 normal forwards;
}

.buttonWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: fadeIn 500ms ease 0s 1 normal forwards;
    color: white;
    padding: 0 1rem;
    max-width: 400px;
    margin: 0 auto;
}
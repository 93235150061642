#navbar-container {
    background-color: #1d2a35;
}


div.navbar-content.clearfix {
    box-shadow: none !important;
    background-color: transparent;
}

.list-group.bg-trans.header {
    margin: 13px 0px 10px 20px !important;
    float: left;
    border-radius: 3px !important;
}

.panel-control {
    height: 0% !important;
}

.nano.scrollable {
    height: 0px;
}

.tgl-menu-btn {
    margin-left: 7px;
}

.navbar-brand {
    display: inline-block;
}

span.navbar-brand {
    margin-left: 55px;
    margin-top: 3px;
}


#container.mainnav-in .navbar-brand {
    width: unset;
}

header#navbar {
    position: relative !important;
}

.btn-search {
    background-color: #eaeaea;
    border-color: #eaeaea !important;
    color: #1d2a35;
}

.btn-search:active
.btn-search:focus,
.btn-search:hover {
    background-color: #03a9f4;
    border-color: #03a9f4 !important;
    color: white;
}

ul.nav.navbar-top-links > li > button.ButtonLink {
    height: 59px;
}

li.dropdown {
    margin-right: 5px;
}

li.dropdown:hover {
    cursor: pointer;
}

i.pli-bell {
    color: white !important;
}

.navbar-top-links > li > a:hover {
    background-color: transparent !important;
}


/* .btn.btn-primary.search {
	background-color: #1D2A35;
	border-color: #1D2A35 !important;	
}

.btn.btn-primary.search:hover {
	background-color: #32495D;
	border-color: #32495D !important;	
} */


li.aside-toggle-hidden {
    visibility: hidden;
}

.panel-control > .input-group-wrap {
    max-width: 800px;
    padding: 0 55px 0 0px;
}

@media (min-width: 775px ) {
    .dropdown-menu.notification {
        left: -230px !important;
    }
}

@media (min-width: 800px) {
    .navbar-brand {
        margin-left: 5px;
    }
}

@media (max-width: 320px) {
    img.brand-logo {
        height: 15px;
        margin-left: 5px;
        margin-top: 20px;
    }

    .navbar-brand {
        margin-left: 55px !important;
    }

}

@media (max-width: 414px) {
    .panel-control > .input-group-wrap {
        padding-right: 0px;
        padding-left: 0px !important;
        max-width: 200px !important;
    }

    input.form-control {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }

    img.brand-logo {
        height: 18px;
        margin-left: 5px;
        margin-top: 18px;
    }

    .navbar-brand {
        margin-left: 45px !important;
    }

}

@media (max-width: 768px) {
    .panel-control > .input-group-wrap {
        max-width: 480px !important;;
        padding-left: 15px !important;;
    }

    span.navbar-brand {
        margin-left: 30px
    }

}

@media (max-width: 900px) {
    .panel-control > .input-group-wrap {
        max-width: 550px !important;
        padding-left: 15px !important;;
    }

    span.navbar-brand {
        margin-left: 30px
    }

}


@media (max-width: 1024px) {
    .panel-control > .input-group-wrap {
        max-width: 650px;
    }

}


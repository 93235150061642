.ag-paging-row-summary-panel {
    display: none;
}

.ag-grid-normal-lineheight {
    line-height: normal !important;
}

.ag-filter-body-wrapper {
    min-width: 20rem;
}
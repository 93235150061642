a {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}


#container {
    min-height: 100vh;
    height: auto;
    position: relative;
    min-width: 290px;
    overflow: hidden;
    /* background: linear-gradient(141deg, #374F66 0%, #1D2A35 75%); */
}

div.cls-container.auth {
    background: linear-gradient(141deg, #374F66 0%, #1D2A35 75%);
    min-height: 101vh !important;
}

div.cls-container.auth.signup {
    background-color: #f6f6f6 !important;
    background: none;
}

div.cls-container.auth.login {
    background-image: url("./img/Town.jpg");
    background-position: center;
    background-size: cover;
}

div.cls-container.auth.reset-pw {
    background-image: url("./img/Desert.jpg");
    background-position: center;
    background-size: cover;
}


div.cls-container.auth.signup {
    background-image: url("./img/Hyperloop.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}


.navbar-1 {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1000;
    display: block;
    border-bottom: 1px solid #eaeaea;
    box-shadow: 0 0 6px 0 rgba(177, 194, 219, .8);
    background-color: #fff;
    height: 66px;
}

.content-wrapper-nav {
    position: relative;
    display: flex;
    overflow: visible;
    max-height: 65px;
    height: 65px;
    max-width: 1190px;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding: 0;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-style: none;
}

.brand.w--current {
    z-index: 101;
}

.brand, .brand.w--current {
    max-width: 110px;
    margin-left: 20px;
}

.brand {
    z-index: 100;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: opacity 350ms ease;
    transition: opacity 350ms ease;
    -webkit-box-pack: center
}

.w-nav-brand {
    float: left;
    text-decoration: none;
    color: #333;
    position: relative;
}

.image-2 {
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
    max-width: 100%;
}

.image-2:hover {
    border-style: none;
    -webkit-perspective: 1px;
    perspective: 1px;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.nav-menu {
    z-index: 102;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    margin-bottom: 0;
    margin-left: 25px;
    float: right;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
}

.navlink {
    right: 0;
    margin-left: 0;
    padding: 15px 25px 15px 11px;
    float: none;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border: 3px solid transparent;
    background-color: #fff;
    transition: background-color .2s ease;
    color: #505050 !important;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;

    margin-right: 0;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    bottom: 0;
    clear: none;

    position: relative;
    display: block;

}


.w-nav-link {
    display: inline-block;
    color: #222;
    padding: 20px;
    text-align: left;
    /* margin-left: auto; */
    margin-right: 0;
    vertical-align: top;
    text-decoration: none;
}

.navlink.login {
    position: absolute;
    display: block;
    padding-right: 25px;
    -webkit-transition-duration: 425ms;
    transition-duration: 425ms;
}

.navlink.login.w-nav-link:hover {
    background-color: #f7f7f7;
}


/* #bg-overlay.bg-img {
    z-index: -1;
}

.cls-content.cls-content-sm {
    margin: 0 auto;
    position: relative;

}

.cls-content {
    margin-top: -550px !important;
    padding: unset !important;
    text-align: center;
    vertical-align: middle;
} */

.cls-content-sm.panel.img {
    margin-bottom: 10px;
    background-color: unset;
}

p.text-semibold.mar-no.slider {
    font-size: 12px;
}

ul.row.wz-steps.wz-icon-bw.wz-nav-off.mar-top {
    margin-left: 2px;
    margin-right: 2px;
    padding-right: 8px;
}

div.thumbnail.noborder.logo {
    background: none;
    border: none;
}

i.icon-2x {
    font-size: 1.75em;
}

.thumbnail.noborder.logo img {
    margin-top: 25px;
    float: none !important;
    display: block;
    max-width: 120px;
    height: auto;
}

a.btn.btn-primary.btn-lg.btn-block {
    color: white;
    background-color: #03a9f4 !important;
    border-color: #03a9f4 !important;
}

.panel.white {
    background-color: white;
    margin-bottom: 40px;
}

i.icon-2x {
    color: #1D2A35;
}

div.progress-bar-primary {
    background-color: #1D2A35;

}

.btn.previous {
    margin-right: 5px;
}


a.btn-primary {
    background-color: #03a9f4;
    border-color: #03a9f4 !important;
    color: white !important;
}

a.btn-primary:hover {
    background-color: #1F7DA4 !important;
    border-color: #1F7DA4 !important;
}

a.btn-primary:active {
    background-color: #03a9f4 !important;
    border-color: #03a9f4 !important;
}

a.btn-primary:focus {
    background-color: #03a9f4;
    border-color: #03a9f4 !important;
}

.btn.btn-danger,
.btn.btn-danger:hover {
    color: white;
}

.btn-link.white {
    color: white !important;
    font-weight: 600;
    /* margin-left: 35px; */
    float: left;
    margin-top: -35px;
    float: left;
    z-index: 5;
}


li.signup-tab.active > a > span > i {
    color: #506277;
}

li.signup-tab > a > span > i {
    color: #A7B1BC
}

.thin-border {
    border: solid 1px #e2e2e2;
}


.navbar-content-signup {
    background-color: white;
    /* height: 66px; */
}

ul.nav.navbar-top-links > li > a {
    color: #505050;
    font-weight: 600;
}

.error.single {
    margin-left: 70px;
}

@media (max-width: 321px ) {
    .thumbnail.noborder.logo img {
        margin-top: 20px;
        max-width: 90px;
    }

    .btn-link.white {
        margin-top: 20px;
    }

    .form-control.custom-placeholder.admin-signup {
        width: 177px !important;
    }

    .cls-content.auth {
        padding-top: 5vh;
    }

    .error.single {
        margin-left: 2px !important;
    }
}

@media (max-width: 376px) and (min-width: 321px) {
    .thumbnail.noborder.logo img {
        margin-top: 30px;
        max-width: 100px;
    }

    .btn-link.white {
        margin-top: -25px;
    }

    .form-control.custom-placeholder.admin-signup {
        width: 195px !important;
    }
}

@media (max-width: 415px) {
    .btn-link.white {
        margin-top: -80px;
    }

    .form-control.custom-placeholder.admin-signup {
        width: 250px
    }

    .error.single {
        margin-left: 12px
    }
}

@media (max-width: 440px) {
    .form-control.custom-placeholder.admin-signup {
        width: 250px
    }
}


.form-control.confirmation {
    width: 50%;
    margin: 0 auto;
}
/* @import '~antd/dist/antd.css'; */


div.bulletin-upload > .ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-done {
    width: 160px !important;
    padding: 0 !important;
}


.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    border-color: #d6b27e;
    background: #d6b27e;
}

.react-form-builder-form .rfb-item label.radio-label span {
    vertical-align: middle;
}

.ant-radio-button-wrapper:hover {
    color: #d6b27e;
}


.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #d6b27e;
}

.ant-menu-horizontal > .ant-menu-item {
    border-bottom: 1px solid transparent;
}

.ant-menu-horizontal > .ant-menu-item-selected {
    color: #1d2a35;
    border-bottom: 1px solid #1d2a35;
}

.ant-menu-horizontal > .ant-menu-item:hover {
    color: #1d2a35;
    border-bottom: 1px solid #1d2a35;
}


/* DUE DATE CALENDAR */
/* .ant-picker-calendar-mode-switch {
	display: none;
}

.ant-picker-calendar-header {
	margin-bottom: 5px;
}

.ant-picker-calendar-year-select{
	margin-right: 5px !important;
} */

.ant-picker.due-date > .ant-picker-input > input {
    font-size: 12px !important;
    cursor: pointer;
    color: #505050;
}

.ant-picker.due-date > .ant-picker-input > input:disabled {
    cursor: not-allowed;
    color: #505050;
}

.ant-picker-input > input:disabled {
    color: #505050;
}

/* .schedule-time-picker .ant-picker-ok > button > span {
	display: none;
}

.schedule-time-picker .ant-picker-ok > button::after {
	content: "Save";
} */

.schedule-time-picker .ant-picker-time-panel-column:nth-child(2) {
    display: none;
}

.schedule-time-picker .ant-select-selector {
    padding-left: 26px !important;
}

/* MODAL */
.ant-modal-header {
    padding: 0 0 5px 0 !important;
}

.ant-modal-title {
    color: #1d2a35 !important;
    font-weight: 600 !important;
}

.ant-modal-sub-title {
    font-weight: 400 !important;
}

div.ant-modal-mask {
    z-index: 1102 !important;
}

div.ant-modal-wrap {
    z-index: 1103 !important;
}

.ant-result-title {
    color: #1d2a35;
}

.ant-result-subtitle {
    color: #505050;
}


.ant-list-item ul > li {
    list-style-type: disc !important;
}

.ant-popover-message-title {
    color: #1d2a35;
}

.ant-popover-buttons button.ant-btn-primary {
    background-color: #d6b17e;
    border-color: #d6b17e;
    text-shadow: none;
}

.ant-popover-buttons button.discard {
    border-color: #1d2a35;
    color: #1d2a35;
}

.ant-popconfirm-buttons .ant-btn-default:not(:disabled):hover {
    color: white;
}

.ant-list-item-action-split {
    background-color: #ccc !important;
}

/*.ant-list .ant-list-item {*/
/*    display: inline-block;*/
/*}*/

.ant-list .ant-list-item .ant-list-item-action {
    margin-inline-start: 12px;
}

/*span.anticon.anticon-close-circle {*/
/*    color: #1d2a35;*/
/*}*/

.submission-attachments-field .ant-upload-list-item.ant-upload-list-item-uploading.ant-upload-list-item-list-type-picture {
    margin-top: 0;
    margin-bottom: 10px;
}

.ant-input[type='tel'] {
    color: #505050;
}

.ant-alert-message-no-bottom-margin .ant-alert-message {
    margin-bottom: 0;
}

.ant-image-preview-mask, .ant-image-preview-wrap, .ant-image-preview-operations-wrapper {
    z-index: 11110;
}

.ant-image-preview-operations {
    background-color: #1d2a35;
    height: 60px;
}

.ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.8);
}


.ant-image-preview-img, .ant-image-preview-wrap {
    max-height: calc(100% - 60px);
    margin-top: 60px;
}

/* Fixes margin between modal buttons after AntD upgrade */
.ant-modal-footer button + button {
    margin-bottom: 0;
    margin-left: 8px;
}

/* Prevents react-form-builder from overriding AntD's checkbox label display property */
label.ant-checkbox-wrapper.checkbox-label {
    display: flex !important;
}

label.ant-checkbox-wrapper.checkbox-label.option-inline {
    display: inline-flex !important;
}

/* Add right margin back to menu item icons that are wrapped in paragraph tags */
.ant-menu-title-content .anticon, .ant-dropdown-menu-title-content .anticon {
    margin-right: 1rem;
}

/* Changes how AntD adds weight to active tab pane. They use text-shadow, which looks blurry in some browsers */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
    font-weight: 500;
    color: var(--zero-light-blue) !important;
}

/* Overrides the color of the bar underneath an active tab label */
.ant-tabs .ant-tabs-ink-bar {
    background: var(--zero-light-blue) !important;
}

/*.row {*/
/*    !*margin: 0 0;*!*/
/*    padding: 0 5px;*/
/*}*/

/*.mar-btm-5 {*/
/*    margin: 5px 5px 5px 0;*/
/*}*/

/*.panel-body {*/
/*    margin: 0 5px 0 5px;*/
/*}*/

/*.panel-body.pad-15, .react-form-builder-form {*/
/*    padding: 0;*/
/*    margin: 0;*/
/*}*/

/* Use ZERO blue for checkbox colors */
.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: var(--zero-light-blue);
    border-color: var(--zero-light-blue);
}

/* User ZERO blue for radio buttons */
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: var(--zero-light-blue);
    background-color: var(--zero-light-blue);
}

.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after, .ant-radio-wrapper .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
    background-color: white;
    transform: scale(0.375);
}

.ant-notification {
    z-index: 1500;
}

[class^="ant-spin"], .ant-tag {
    font-family: var(--zero-fonts);
}

[class^="ant-radio"], .ant-tag, .ant-radio, .ant-radio-wrapper, .ant-collapse, .ant-checkbox, 
.ant-checkbox-wrapper, .ant-alert, .ant-tabs, .ant-modal, .ant-modal-header, .ant-modal-title, .ant-modal-body, .ant-modal-content, 
.ant-select, .ant-select-single, .ant-select-selector,.ant-select-selection-item, .ant-select-selection-search, .ant-select-customize-input, 
.ant-select-show-search, .ant-select-selection-search-input, .ant-picker, .ant-picker-input, .ant-upload-wrapper, .ant-upload-drag,
.ant-dropdown-menu-title-content, .ant-space {
    font-family: var(--zero-fonts);
}

.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.notes-month {
    font-size: 28px;
    text-align: center;
}

.notes-month section {
    font-size: 28px;
}

/* FullCalendar long titles -- hidden */
.fc-daygrid-day {
    overflow: hidden;
    text-overflow: ellipsis;
}

.fc-daygrid-day .event-title {
    text-overflow: ellipsis;
}

.zero-event b {
    margin-right: 2px;
}
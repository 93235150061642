.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 999999;
}

.alertOffline {
    position: fixed;
    top: 65px;
    width: 100%;
    padding: 20px;
    z-index: 1000000;
    background-color: #E75C50;
    color: white;
    font-size: 15px;
    font-weight: 500;
    border-radius: 4px;
}

@media screen and (min-width: 400px) {
    .alertOffline {
        right: 20px;
        max-width: 360px;
    }
}

.messageHeader {
    display: flex;
    justify-content: space-between;
}

.alertReconnected {
    background-color: #8BBD57;
}

.icon {
    font-size: 120%;
    vertical-align: text-bottom;
    margin-right: 10px;
}

.link, .link:hover, link:visited, link:active, link:focus {
    color: white !important;
    text-decoration: underline !important;
}

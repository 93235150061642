
h1.page-header {
    display: inline-block;
    margin: 10px 0 0 0;
    font-weight: bold;
    text-transform: none;
    color: #75818F;
}

h6.author {
    color: #45BCDE;
}

.btn.btn-primary.post-btn {
    margin: 15px 0px 0 0;
}


span.label.label-primary.tag {
    float: left;
    display: inline-block;
    margin: 20px 18px 0 0;
}

.btm-margin {
    margin-bottom: 10px;
}

.btn-blue {
    margin-right: 10px !important;
    background-color: #1B2A35;
    border-color: #1B2A35 !important;
    color: white;
    float: right !important;
    margin-top: 15px;
}

.btn-blue:active
.btn-blue:focus,
.btn-blue:hover {
    background-color: #32495D !important;
    border-color: #32495D !important;
    color: white;
}

.panel.article {
    border-radius: 2px;
    padding: 10px 20px 10px 20px;
}

ol.breadcrumb {
    margin-top: 24px;
    margin-left: -15px;
    display: inline-block;
    text-decoration: underline;
}

.breadcrumb li {
    text-decoration: underline;
}

h3.article-title {
    color: #1D2A35 !important;
    /* font-family: 'Raleway', sans-serif; */
    font-size: 200%;
    font-weight: 700;
    margin-left: 18px;
    margin-bottom: 25px;
    display: inline-block;
}

div.dropdown-select {
    display: inline-block;
    width: 180px;
    margin-top: 0px;
    float: right;
}

div.dropdown-select-2 {
    display: inline-block;
    width: 160px;
    margin-top: 13px;
    margin-left: 15px;
    float: right;
}

.img-xs.img-circle {
    margin-right: 5px;

}

.updated-by {
    text-decoration: underline;
    color: #1F7DA4;
}

.panel-body {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.panel-body > p {
    margin-bottom: 0px !important
}

div.note-editor.note-frame.panel.panel-default {
    border: #E8E8E8 1px solid;

}

div.note-palette-title {
    color: #122D47;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

div.note-palette {
    padding: 12px;
}

button.note-color-reset.btn.btn-light {
    display: none;
}

button.note-btn.btn.btn-default.btn-sm.active {
    background-color: #E6E6E6;
}

div.Select.Select--multi.is-clearable.is-searchable {
    margin-top: 0;
}

.timeline-header {
    margin-bottom: 20px;
}

div.dz-size > span {
    display: none !important;
}


@media (max-width: 375px) {
    h3.article {
        font-size: 150%;
    }

    h3.author {
        font-size: 100%;
    }

    div.author-container {
        margin-left: 5px;
    }

    img.img-circle.img-sm.article {
        margin-left: 8px;
    }

    div.panel.article {
        padding: 10px;
    }

    .views {
        margin-right: 6px !important;
    }

    button.btn.btn-tags.bulletin {
        margin-left: 6px !important;
    }

    /* div.views.bulletin {
        margin-right: 6px !important;
    } */
}

.activity-timeline-layout {
    display: flex;
    flex-direction: column;

    .timeline-top-rule {
        width: 100%;
    }

    .details-section {
        padding: 0;
    }

    .timeline-print-header {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .activity-timeline-layout {
        flex-direction: row-reverse;
        gap: 2rem;

        .timeline-top-rule {
            display: none;
        }

        .details-section {
            min-width: 250px;
            max-width: 330px;
            padding: 0;
        }

        .timeline-section {
            min-width: 470px;
            width: 100%;
        }
    }
}

@media print {
    .activity-timeline-layout {
        .details-section, .timeline-top-rule {
            display: none;
        }
    
        .timeline-print-header {
            display: block;
            color: var(--zero-blue) !important;
            margin-bottom: 20px;
            margin-top: 5px;
            font-size: 120%;
            text-decoration: underline;
        }
    }
}


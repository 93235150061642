/**
    Print stylesheet
**/

@media print {
    @page {
        margin: 0.75in;
    }

    body, #content-container {
        background-color: #fff !important;
    }

    i {
        font-weight: normal;
    }

    /*#container {*/
    /*    background-color: white !important;*/
    /*}*/
    .print-7in {
        width: 7in;
        margin: 0 !important;
    }

    .print-6in {
        width: 6in;
        margin: 0 !important;
    }

    .print-avoid-breaks {
        break-inside: avoid;
    }

    .col-print-1 {
        width: 8%;
        float: left;
    }

    .col-print-2 {
        width: 16%;
        float: left;
    }

    .col-print-3 {
        width: 25%;
        float: left;
    }

    .col-print-4 {
        width: 33%;
        float: left;
    }

    .col-print-5 {
        width: 42%;
        float: left;
    }

    .col-print-6 {
        width: 50%;
        float: left;
    }

    .col-print-7 {
        width: 58%;
        float: left;
    }

    .col-print-8 {
        width: 66%;
        float: left;
    }

    .col-print-9 {
        width: 75%;
        float: left;
    }

    .col-print-10 {
        width: 83%;
        float: left;
    }

    .col-print-11 {
        width: 92%;
        float: left;
    }

    .col-print-12 {
        width: 100%;
        float: left;
    }

    input::placeholder, input.custom-placeholder::placeholder, textarea.custom-placeholder::placeholder {
        color: transparent !important;
    }

    .thin-border {
        border: none !important;
    }

    .top-margin-print {
        margin-top: 12px !important;
    }

    .no-border-print {
        border: none !important;
    }

    .react-form-builder-form {
        margin-left: 10px !important;
    }

    .react-form-builder-form > div > div:not(.section-container) {
        break-inside: avoid;
    }

    .no-padding-print, .panel-body.pad-15, .react-form-builder-form {
        padding: 0 !important;
    }

    img.img-circle.img-sm.bulletin {
        max-width: none !important;
    }

    .ButtonLink {
        border: 1px solid rgb(214, 214, 214) !important;
    }

    #postidnumber {
        font-weight: normal;
        color: #808080 !important;
    }

    .print-always-visible {
        display: initial;
    }

    .print-prewrap {
        white-space: pre-wrap;
        border: 1px solid #555;
        padding: 8px;
    }

    .print-border {
        border: 1px solid #555;
        padding: 8px;
    }

    .ant-radio-checked .ant-radio-inner {
        border: 8px solid black !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        border: 8px solid black !important;
    }

    .ant-upload-list-item-card-actions {
        display: none;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        transform: rotate(45deg) scale(1) translate(-100%, -30%) !important;
    }

    .ant-select-arrow {
        display: none !important;
    }

    .ant-list-item-action {
        display: none;
    }

    .ant-picker-suffix {
        display: none;
    }

    .anticon-calendar {
        display: none;
    }

    .ant-tabs-nav {
        display: none !important;
    }

    .panel.incident {
        padding: 0;
    }

    div.default-avatar-circle.bulletin {
        border: 1px solid rgb(80, 80, 80);
    }

    .incident-block {
        margin: 0 !important;
        padding: 0 !important;
    }

    .incident-block:not(:last-child) {
        margin-bottom: 1in !important;
    }

    .incident-block h3 {
        break-after: avoid;
    }

    .dont-print {
        display: none !important;
    }

    .timeline::after {
        display: none;
    }

    .timeline-entry {
        break-inside: avoid;
    }

    .print-avoid-page-break {
        break-inside: avoid;
    }

    .timeline-entry:last-child {
        margin-bottom: 0;
    }

    div.panel.bulletin-post-border {
        border: 0;
        padding: 0;
    }

    #page-content {
        padding: 0 !important;
    }

    #container {
        position: fixed;
        left: 0;
        top: 0;
        width: 7in;
        padding: 0;
    }

    nav#mainnav-container,
    div#inline-sidebar {
        display: none;
    }

    header {
        display: none;
    }

    /*body:not(.initializing), div#content-container {*/
    /*    background-color: white;*/
    /*}*/
    div.post-main-content {
        width: 5.4in;
    }

    div.post-details-content {
        width: 2in;
    }

    div.container-with-header {
        top: 0;
    }

    div.main-content-container {
        margin: 0 auto;
    }

    a[href]:after {
        content: none !important;
    }

    div.post-main-content > div > div.more.pull-right {
        display: none !important;
    }

    div.well.comments {
        display: none;
    }

    .panel.bulletin-make-border.incident {
        border: none;
    }

    /*#page-head {*/
    /*    margin-top: 15px;*/
    /*}*/
    /*#page-content {*/
    /*    margin-bottom: 15px;*/
    /*}*/
    /* POST VIEW */
    div.post-main-content {
        width: 70%;
        display: inline-block !important;
    }

    div.post-details-content {
        width: 28%;
        display: inline-block !important;
    }

    div.comments {
        display: none;
    }

    .print-only {
        display: initial;
    }

    .print-display-block {
        display: block;
    }

    .print-force-display {
        display: initial !important;
    }

    .boxed {
        width: 7in !important;
    }

    .incident-cover-sheet {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .incident-cover-sheet > div {
        line-height: 175%;
    }

    .answer-border {
        max-width: calc(7in - 15px) !important;
    }

    .filter-menu {
        padding-bottom: 8px;
    }

    .print-feed-filter-wrapper {
        display: flex !important;
        flex-direction: column;
    }

    .print-incident-filter-wrapper > .ant-select:nth-of-type(2) {
        margin-left: 0 !important;
        margin-top: 8px !important;
    }

    .print-incident-filter-wrapper > div:nth-of-type(3) {
        margin-left: 0 !important;
        margin-top: 8px !important;
    }

    .print-feed-filter-wrapper > div {
        width: 90% !important;
    }

    .print-feed-filter-wrapper > div:nth-of-type(2) {
        margin-top: 8px !important;
    }

    .print-feed-filter-wrapper span.ant-select-selection-placeholder {
        visibility: hidden;
    }

    .print-feed-filter-wrapper span.ant-select-selection-placeholder:after {
        content: "No filters applied";
        visibility: visible;
        position: absolute;
        left: 0;
        color: #505050 !important;
    }

    .ant-select-selection-item-remove {
        display: none !important;
    }

    .print-incident-chart-wrapper canvas {
        width: 7in !important;
    }

    .print-chart-wrapper canvas {
        width: 6.5in !important;
    }

    /*.bulletin-feed .panel.thin-border {*/
    /*    height: unset !important;*/
    /*}*/
    .print-unset-height {
        height: unset !important;
    }

    .print-feed-nav {
        margin-top: 4px;
    }

    .print-feed-nav li:not(.active) button {
        font-weight: normal !important;
        border: none !important;
    }

    .print-no-y-margins {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .print-no-x-margins {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .print-no-x-pad {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .print-no-y-pad {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    #content-container {
        padding-bottom: 0 !important;
    }

    #frt_base, #bck_base {
        width: 2.25in;
    }

    #frt_base svg, #bck_base svg {
        height: 350px !important;
    }

    #body-tab {
        border: none !important;
    }

    #body-tab div.panel {
        margin: 0 !important;
    }

    .forms-nav-tabs li:not(.active) button {
        border: none !important;
        font-weight: normal !important;
    }

    .form-submissions-chart canvas {
        width: 6.5in !important;
    }

    .form-group .ant-collapse {
        max-width: 98%;
    }

    .form-comment-container {
        max-width: calc(7in - 12px);
    }
}
#organswrapper {
    /*font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;*/
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 0;
    background-color: transparent;
    min-width: 220px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

#organswrapper svg {
    max-height: 100%;
    width: 100%;
    margin: 0;
}

.go_bck, .go_frt {
    fill: #72B8FC;
    opacity: 0.05;
    -webkit-transition: fill 0.1s ease;
    -moz-transition: fill 0.1s ease;
    -o-transition: fill 0.1s ease;
    transition: fill 0.1s ease;
    cursor: pointer;
}

.go_bck:hover, .go_frt:hover {
    opacity: 0.4;
}

#organs-tip {
    font: 14px/16px Trebuchet MS, Helvetica, Arial, sans-serif;
    display: none;
    padding: 5px;
    border: 1px solid #404040;
    color: #404040;
    z-index: 1000;
    float: left;
    position: absolute;
    background: rgba(265, 265, 265, 0.9);
    word-break: keep-all;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

#organs-tip p {
    margin: 0 !important;
    color: #404040 !important;
}

#organs-tip img {
    float: left;
    padding: 3px;
}

@media screen and (max-width: 320px) {
    #organswrapper svg {
        height: 440px;
    }

    #organs-tip {
        max-width: 40%;
    }

    #organs-tip img {
        max-width: 95%;
    }
}

@media screen and (max-width: 400px) and (min-width: 321px) {
    #organswrapper svg {
        height: 510px;
    }
}

@media screen and (max-width: 480px) and (min-width: 401px) {
    #organswrapper svg {
        height: 660px;
    }
}

@media screen and (max-width: 568px) and (min-width: 481px) {
    #organswrapper svg {
        height: 660px;
    }
}

@media screen and (max-width: 685px) and (min-width: 569px) {
    #organswrapper svg {
        height: 680px;
    }
}

@media screen and (max-width: 767px) and (min-width: 686px) {
    #organswrapper svg {
        height: 690px;
    }
}

@media screen and (min-width: 768px) {
    #organswrapper svg {
        height: 700px;
    }
}
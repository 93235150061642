.draftActionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.draftActionButton {
    font-size: 1.5rem;
    padding: 0.2rem 0.5rem;
}

.draftActionButton:hover {
    box-shadow: none !important;
}

.draftActionButton:disabled {
    color: var(--zero-dark-grey) !important;
}

.draftActionSeparator {
    border-right: 1px solid var(--zero-grey);
    height: 2rem;
    align-self: center;
}
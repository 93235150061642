.itemContainer {
    display: flex;
    flex-wrap: wrap;
}

.item {
    padding: 1rem 0;
    width: 50%;
    cursor: pointer;
}

.item:hover {
    background-color: #FBFBFB;
}

.itemIcon {
    margin-right: 0.5rem;
}

@media screen and (min-width: 576px) {
    .itemContainer {
        max-width: 600px;
    }

    .item {
        width: 33.33%;
    }

    .itemIcon {
        margin-right: 1rem;
    }
}

span.menu-title::selection {
    color: white !important;
}


div.list-group.bg-trans > a.list-group-item.active {
    color: rgb(255, 255, 255) !important;
    background-color: #1d2a35 !important;
    font-weight: 600;
    padding-left: 13px;
    padding-right: 13px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin: 0px 5px;
}

.list-group-item.active:hover {
    color: rgb(255, 255, 255) !important;
    background-color: #1d2a35 !important;
    font-weight: 600;
    padding-left: 13px;
    padding-right: 13px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin: 0px 5px;
}

#mainnav .mainnav-profile .list-group-item {
    color: #505050
}

ul.collapse {
    background-color: #fafafa;
}
.react-form-builder,
.react-form-builder-form,
.SortableItem > h3,
.edit-form > div > div > h4 {
    color: #1D2A35;
}

.react-form-builder-preview {
    background-color: #f6f6f6;
}

.editing-form .react-form-builder .react-form-builder-toolbar {
    width: 230px !important;
    margin-top: 10px;
}

.creating-form .react-form-builder .react-form-builder-toolbar {
    width: 230px !important;
    margin-top: 10px;
}

.creating-form.audit .react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list {
    display: none !important;
}

.creating-form.audit .react-form-builder .react-form-builder-preview .edit-form .form-group .checkbox {
    display: none !important;
}

.creating-form.audit .react-form-builder .react-form-builder-preview .edit-form .form-group .control-label {
    display: none !important;
}

.editing-form.audit .react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list {
    display: none !important;
}

.editing-form.audit .react-form-builder .react-form-builder-preview .edit-form .form-group .checkbox {
    display: none !important;
}

.editing-form.audit .react-form-builder .react-form-builder-preview .edit-form .form-group .control-label {
    display: none !important;
}

.react-form-builder-form .rfb-item .form-group a {
    color: #03a9f4;
}

.form-group img {
    max-width: 700px;
    height: auto;
}

.toolbar-header > .label.label-default {
    background-color: #03a9f4;
    color: white;
}

.react-form-builder-toolbar > h4 {
    /* visibility: hidden; */
    position: relative;
    font-size: 0px;
    height: 20px;
}

.react-form-builder-toolbar > h4::after {
    color: #1D2A35;
    content: 'Fields';
    visibility: visible;
    position: absolute;
    top: 0;
    left: 80px;
    font-size: 20px;
}

.react-form-builder .react-form-builder-preview {
    min-height: 600px !important;
    box-shadow: none !important;
}

.react-form-builder-form {
    box-shadow: none !important;
    background-color: white;
    /* border: 1px solid #ddd; */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px !important;
}

.react-form-builder-form .form-control:focus {
    border-color: #03a9f4;
}

/* .editing-form .react-form-builder .react-form-builder-preview .Sortable .rfb-item {
    padding: 10px 20px;
} */

/* .SortableItem.rfb-item {
    padding: 20px !important;
} */

.react-form-builder-form.builder {
    padding-left: 20px;
    padding-right: 20px;
}

.react-form-builder-form.builder .SortableItem.rfb-item {
    padding: 10px !important;
    /* background-color: #f6f6f6; */
    /* margin-bottom: 10px; */
    border-radius: 4px;
}

.react-form-builder-form.builder .SortableItem.rfb-item.active,
.react-form-builder-form.builder .SortableItem.rfb-item:hover {
    background-color: #f6f6f6;
}

.react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header .label {
    top: -15px !important;
    left: 0px !important;
}

.react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header .toolbar-header-buttons {
    top: -8px !important;
    right: 0px !important;
}

.react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header .toolbar-header-buttons .btn {
    background-color: white;
    border: 1px solid #AAAAAA;
    margin-right: 5px;
}

/* .fa-pencil-square-o::before {
    content: "\f303" !important;
} */

.react-form-builder-form .SortableItem {
    padding: 5px 0px !important;
}

.react-form-builder-form label.form-label {
    margin-bottom: 1px;
    width: fit-content;
    display: block;
}

.react-form-builder-form .form-group > label {
    display: block;
}

.react-form-builder-form .form-group > label.option-inline {
    display: inline-block;
    margin-right: 10px;
}

.react-form-builder .react-form-builder-preview label span:not(.label-required),
.react-form-builder-form .rfb-item label span:not(.label-required) {
    font-weight: 600;
    font-size: 14px;
}

.react-form-builder-form .rfb-item label.checkbox-label span,
.react-form-builder-form .rfb-item label.radio-label span {
    font-weight: normal;
}

.tag-flex {
    display: flex;
    flex-wrap: wrap;
}

.tag-flex div {
    margin: 4px;
}

.answer-border {
    color: #505050;
    min-height: 25px;
}

.answer-border p:last-child {
    margin-bottom: 0;
}

div.btn-toolbar {
    position: absolute;
    right: 0px;
    bottom: -50px;
}

div.btn-toolbar > input.btn.btn-school.btn-big.btn-agree {
    background-color: #03a9f4 !important;
    color: white !important;
}

div.btn-toolbar > input.btn.btn-school.btn-big.btn-agree:hover {
    background-color: #1F7DA4 !important;
    border-color: #1F7DA4 !important;
}

div.alert.alert-danger.validation-error {
    /* visibility: hidden; */
    position: relative;
    margin-bottom: 10px;
    z-index: 1;
}

.form-place-holder {
    height: 120px;
}

.form-place-holder > div {
    visibility: hidden;
    position: relative;
}

.form-place-holder > div::after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: 'Welcome to the Form Builder! To get started, click on any field.' !important;
}

.react-form-builder-form .rfb-item .form-group .checkbox-label,
.react-form-builder-form .rfb-item .form-group .radio-label {
    color: #505050;
    width: fit-content;
}

label.ant-checkbox-wrapper {
    margin-left: 0px !important;
}

input.custom-form-title {
    outline: 0;
    border-width: 0 0 1.5px;
    /* border-color: #1D2A35; */
    border-radius: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #1D2A35;
}

.form-control.custom-form-title:focus {
    border-color: #EDEDED !important;
}


.react-form-builder-form .rfb-item .form-group .label-required {
    margin-left: 0px !important;
}

.label-required {
    /* visibility: hidden; */
    position: relative;
    font-size: 0px;
}

.label-required::after {
    content: "*" !important;
    visibility: visible;
    /* position: absolute; */
    top: -10px;
    left: 0;
    color: rgb(0, 0, 0);
    /* padding-top: 5px; */
    font-size: 12px;
}

.react-form-builder .react-form-builder-preview h3.static {
    /* display: inline-block;
    vertical-align: sub; */
    margin-top: 10px;
}

.react-form-builder .react-form-builder-preview p.static {
    /* display: inline-block; */
}

.react-form-builder .react-form-builder-preview p.logic,
.react-form-builder .react-form-builder-preview label p {
    color: white;
    font-weight: 600;
    font-size: 80%;
    margin-bottom: 0;
    margin-right: 5px;
    padding: 3px 5px;
    background-color: #8bc34a;
    border-radius: 4px;
    display: inline;
}

p.logic-badge {
    color: white;
    font-weight: 600;
    font-size: 80%;
    margin-bottom: 0;
    margin-right: 5px;
    padding: 5px 8px;
    background-color: #8bc34a;
    border-radius: 3px;
    display: inline-block;
}

p.element-badge {
    color: white;
    font-weight: 600;
    font-size: 80%;
    margin-bottom: 0;
    margin-right: 5px;
    padding: 5px 8px;
    background-color: #1d2a35;
    border-radius: 3px;
    display: inline-block;
}

/* .SortableItem.rfb-item:hover .logic-badge,
.SortableItem.rfb-item:hover .element-badge,
.SortableItem.rfb-item:hover button.add-field {
    display: inline;
} */

button.add-field {
    display: none;
    padding: 2px 10px !important;
}

.field-button-group {
    display: none;
    background-color: #f6f6f6;
    z-index: 1;
    margin-right: 5px;
}

.field-button-group > button.btn {
    padding: 2px 10px !important;
    margin-right: 5px !important;
}

.SortableItem.rfb-item.active .field-button-group,
.SortableItem.rfb-item:hover .field-button-group {
    display: inline;
    position: absolute;
    right: 40px;
}

.SortableItem.rfb-item.nested .field-button-group {
    display: none;
}

.SortableItem.rfb-item.nested:hover .field-button-group {
    display: inline;
    position: absolute;
    right: 28px;
}

.rdw-inline-wrapper {
    align-items: center;
}

.rdw-editor-wrapper {
    border: 1px solid #F1F1F1
}

.rdw-editor-toolbar {
    border: none;
    border-bottom: 1px solid #F1F1F1;
}

.rdw-editor-main {
    height: 75px;
}

.edit-form {
    display: none;
}

.field-editor > .modal-dialog > .modal-content {
    background-color: #fafafa;
}

.tox-tinymce-aux {
    z-index: 10000000 !important;
}


.ant-popover.ant-popover-placement-bottomRight {
    position: fixed;
}


.signature-pad {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 10px;
    width: 100%;
    height: 100%;
    max-width: 700px;
    max-height: 460px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
    border-radius: 4px;
    padding: 16px;
}

.signature-pad--body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #f4f4f4;
}

.signature-pad--body canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}


canvas {
    width: 100%;
    height: 200px;
}

.signature-pad--footer {
    color: #C3C3C3;
    text-align: center;
    font-size: 15px !important;
    margin-top: 8px;
}

.signature-pad--actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 8px;
}


@media (max-width: 440px) {
    .form-place-holder {
        height: 190px !important;
    }
}

@media (min-width: 441px) and (max-width: 535px) {
    .form-place-holder {
        height: 155px !important;
    }
}

@media (max-width: 1000px) {
    .editing-form .react-form-builder .react-form-builder-toolbar {
        display: none !important;
    }

    .react-form-builder-form {
        padding: 0px !important;
    }
}

